import { useState } from "react";
import * as styles from "./app.module.scss";
import classnames from "classnames";

type ViewModel = {
    title: string;
    sections: SectionViewModel[];
};

type SectionViewModel = {
    sectionTitle: string;
    items: ItemViewModel[];
};

type ItemViewModel = {
    question: string;
    answer: string;
};

function App() {
    const data = (window as any).app.preloadState.faqAccordion as ViewModel;

    return <div>
        {data.sections.map(section => (
            <div>
                <div className={styles.sectionTitle}>{section.sectionTitle} </div>
                {section.items.map(item => (
                    <div>
                        <ExpandableContent title={item.question} content={item.answer} />
                    </div>
                ))}
            </div>
        ))}
    </div>
}

function ExpandableContent({ title, content }: { title: string, content: string }) {
    const [isOpen, setIsOpen] = useState(false);

    return <div className={ styles.accordionItem}>
        <div className={classnames(styles.expandableTitle, isOpen ? styles.isOpen : "")} onClick={() => setIsOpen(!isOpen)}>{title}</div>
        <div className={classnames(styles.expandableContent, isOpen ? styles.isOpen : "") } dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
}

export default App;
